<script setup>
// const cart = useCartStore()
// const config = useRuntimeConfig()
// const loading = ref(false)

const auth = useAuthStore()

const props = defineProps({
  cta: {
    type: Boolean,
    default: false
  },
  product: {
    type: Object,
    required: true
  },
  small: {
    type: Boolean,
    default: false
  },
  snap: {
    type: Boolean,
    default: false
  }
})

const formatter = new Intl.NumberFormat('sr-RS', {
  style: 'currency',
  currency: 'RSD',
  minimumFractionDigits: 2
})

// const add = async (id) => {
//   try {
//     loading.value = true
//     cart.useLoading(true)
//     const x = await $fetch(config.public.CMS + '/wp-json/wc/store/cart/add-item', {
//       method: 'post',
//       headers: {
//         'cart-token': cart.cartToken,
//         'content-type': 'application/json'
//       },
//       body: { id, quantity: 1 }
//     })
//     cart.updateCart(x)
//     cart.useMiniCart(true)
//   }
//   catch (error) {
//     console.error(error)
//   }
//   finally {
//     loading.value = false
//     cart.useLoading(false)
//   }
// }

// eslint-disable-next-line no-useless-escape
const to = computed(() => (props.product.permalink || '/')?.replace(/^.*\/\/[^\/]+/, ''))
function limitCharacters(text, maxLength) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }
  else {
    return text
  }
}
const outofstock = computed(() => props.product.stock_status === 'outofstock' || props.product.price === '0.00')
const uom = computed(() => props.product?.meta_data?.filter(x => x.key === '_uom')[0]?.value?.value || '')
</script>

<template>
  <div>
    <NuxtLink
      :to="to"
      :class="['card', { outofstock, snap }]"
    >
      <!-- <span v-if="product.on_sale" class="sale">-{{ (100 + (((product.price || product?.prices?.price) /
        (product.regular_price || product?.prices?.regular_price)) * -
        100)).toFixed()
        }}%</span> -->
      <vIcon
        v-if="outofstock && !auth.isAdmin"
        id="cart-off-outline"
        class="sale mode"
        fill="#FFFFFF80"
      />
      <vWish
        :id="+product.id"
        class="mode"
        color="#FFFFFF80"
      />
      <div class="placeholder">
        <img
          v-if="Boolean(product.images?.length)"
          :src="product.images[0]?.src"
          alt
        >
        <img
          v-if="product.image"
          :src="product.image"
          alt
        >
      </div>
      <div class="fx">
        <div
          class="name"
          v-text="limitCharacters(product?.name, 36)"
        />
        <!-- <div>{{ product.permalink }}</div> -->
        <div class="prices">
          <div>
            <!-- <div
              v-if="(product.regular_price !== product.price) || (product?.prices?.regular_price !== product?.prices?.price)"
              v-text="formatter.format(product.regular_price || product?.prices?.regular_price)" class="price_sale" /> -->
            <div
              v-if="(product.price || product?.prices?.price) && product.price !== '0.00'"
              class="price"
              v-text="formatter.format(product.price || product?.prices?.price) + (uom && (' / ' + uom))"
            />
          </div>
          <!-- <div @click="remove(item.key)" class="remove">Ukloni proizvod</div> -->
        </div>

      </div>
    </NuxtLink>
  </div>
</template>

<style scoped>
.mode {
  mix-blend-mode: difference;
}

.fx {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &.outofstock {

    & .name,
    & .prices,
    & img {
      opacity: 0.25;
    }
  }

  &.snap {
    /* 75vw */
    width: 75vw;
    scroll-snap-align: center;
  }
}

:deep(.wish) {
  --size: 36px;
  width: var(--size);
  height: var(--size);
  position: absolute;
  top: -2px;
  right: -2px;
  z-index: 3;
  display: grid;
  place-items: center;
  cursor: pointer;
  /* fill: #FFFFFF80; */
}

.placeholder {
  background-color: rgb(var(--color-rgb) / .06);
  aspect-ratio: 1;
  display: grid;
  place-items: center;
  margin-bottom: 8px;
  position: relative;
  border-radius: 4px;
  z-index: 0;

  & img {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    z-index: 1;
    mix-blend-mode: multiply;
  }
}

.name {
  font-size: 12px;
  /* padding-bottom: 4px; */
  font-weight: 400;
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

.prices {
  padding-top: 4px;
}

.price_sale {
  font-size: 11px;
  text-decoration: line-through;
  color: red;
  font-weight: 600;
}

.price {
  font-size: 12px;
  font-weight: 800;
}

.sale {
  position: absolute;
  top: 4px;
  left: 6px;
  z-index: 3;
}

.featured {
  position: absolute;
  bottom: 1px;
  right: 6px;
}

.add {
  margin-top: 16px;
  display: grid;
  place-items: center;
  height: 48px;
  background-color: black;
  color: white;
  width: 100%;
  font-size: 14px;
  line-height: 1;
  padding: 0 16px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: rgba(0 0 0 / .25) 0px 13px 27px -5px, rgba(0 0 0 / .5) 0px 8px 16px -8px;
  user-select: none;

  &:active {
    transform: scale(.95);
    box-shadow: none;
  }

  &.disabled {
    background-color: rgb(0 0 0 / .03);
    color: rgb(0 0 0 / .24);
    font-weight: 600;
    box-shadow: none;
    pointer-events: none;
  }

  &:disabled {
    box-shadow: none;
  }
}
</style>
